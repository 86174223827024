import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "white",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("path", {
        d: "M12 4L13.425 5.4L7.825 11L20 11L20 13L7.825 13L13.425 18.6L12 20L4 12L12 4Z",
        fill: "inherit"
      })
    ], -1)
  ])))
}
export default { render: render }